import axios from "@axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchAttendances(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/attendances", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchAttendance(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`attendances/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchAttendanceCalendarData(ctx, data) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`attendance-calendar-data/${data.hashid}`, {
                        params: data.queryParams
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchAttendanceInfo(ctx, data) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`attendance-calendar-info`, {
                        params: data.queryParams
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchNHRs(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/hire-request", {
                        params: {
                            perPage: 100,
                            page: 0
                        }
                    })
                    .then(response => {
                        resolve(response.data.data);
                    })
                    .catch(error => reject(error));
            });
        },
        addRecruitertoNHR(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .get(
                        `hire-request/${userData.nhrId}/assign-to-bba/${userData.recruiter}`
                    )
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        addAttendancetoNHR(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`hire-request/${userData.nhrId}/assign-attendances`, {
                        attendances: userData.attendances
                    })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        addAttendance(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("attendances", userData, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        updateAttendance(ctx, attendance) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`attendances/${attendance.id}`, attendance.data, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        // updateAttendanceStatus(ctx, attendance) {
        //     return new Promise((resolve, reject) => {
        //         axios
        //             .post(`attendances/statusUpdate/${attendance.id}`, {
        //                 status: 1
        //             })
        //             .then(response => {
        //                 resolve(response);
        //             });
        //     });
        // },
        removeAttendance(ctx, userId) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`attendances/${userId}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        removeMultipleAttendances(ctx, ids) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/deleteMultipleAttendances/`, { ids: ids })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        fetchAttendanceSource(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("attendances-source", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        addSource(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .post("attendances-source", { name: queryParams.name })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        removeSource(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`attendances-source/${queryParams.id}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        getCustomFields() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/attendances/create")
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        addDesignation(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/designations", { name: queryParams.name })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        fetchDesignationsList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/designations", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        }
    }
};
