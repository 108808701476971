<template>
  <div class="">
    <div class="row">
      <!-- Sidebar -->

      <b-col cols="12">
        <div class="app-calendar overflow-hidden border p-1">
          <full-calendar
            ref="refCalendar"
            :options="calendarOptions"
            class="full-calendar"
          />
        </div>
      </b-col>
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import store from "@/store";
import { onUnmounted, watch } from "@vue/composition-api";
import attendanceStoreModule from "@/views/attendance/attendanceStoreModule";
import useCalendar from "./useCalendar";
import { BImg } from "bootstrap-vue";
export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    BImg,
  },
  props: {
    userHashid: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const CALENDAR_APP_STORE_MODULE_NAME = "calendar";

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME))
      store.registerModule(
        CALENDAR_APP_STORE_MODULE_NAME,
        attendanceStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME))
        store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME);
    });

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,
      fetchAttendance,
      selectedDates,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useCalendar(props.userHashid);

    fetchEvents();
    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      fetchEvents,
      calendarOptions,
      fetchAttendance,
      selectedDates,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    };

  },

  data() {
    return {
      options: {
        1: require("@/assets/images/moods/angry.svg"),
        2: require("@/assets/images/moods/thinking.svg"),
        3: require("@/assets/images/moods/neutral.svg"),
        4: require("@/assets/images/moods/shy.svg"),
        5: require("@/assets/images/moods/cute.svg"),
      },
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
.full-calendar {
  cursor: pointer;
  
}
.selected-date {
 background-color: yellow !important; /* or any color you want */
 }
</style>